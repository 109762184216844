import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { SWAConfig } from '../../../project/shared/interfaces/swaconfig';
import { Observable, last } from 'rxjs';
import { WorkloadData } from '../interfaces/workloadData.interface';
import { AssignmentData } from '../interfaces/assignmentData.interface';
import { ChangelogAqFilter } from 'src/app/report/shared/interfaces/changelog-aq-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private readonly httpService: HttpService) { }

  public getRouteStationUsageReport(): Observable<WorkloadData> {
    return this.httpService.get<WorkloadData>('Report/RouteStationUsageReport');
  }

  public getAssignedReport(): Observable<AssignmentData> {
    return this.httpService.get<AssignmentData>('Report/AssignedReport');
  }

  public getRouteStationVoltageWithFilter(id: number, dateFrom: string, dateTo: string): Observable<any> {
    return this.httpService.get<any>('report/' + id + '/lastvoltagemeasurement/' + dateFrom + '/' + dateTo)
  }

  public getRouteStationsCurrentWithFilter(id: number, dateFrom: string, dateTo: string): Observable<any> {
    return this.httpService.get<any>('report/' + id + '/lastcurrentmeasurement/' + dateFrom + '/' + dateTo)
  }

  public getRouteStationsSTMWithFilter(id: number, dateFrom: string, dateTo: string, deKanal: number): Observable<any> {
    return this.httpService.get<any>('report/' + id + '/laststmmeasurement/' + dateFrom + '/' + dateTo + '?dekanal=' + deKanal)
  }

  public getRouteStationsSpeedWithFilter(id: number, dateFrom: string, dateTo: string, minutes: number, deKanal: number, projectId: number): Observable<any> {
    return this.httpService.get<any>('report/' + id + '/SpeedReport/' + dateFrom + '/' + dateTo + '?minutes=' + minutes + '&deKanal=' + deKanal + '&projectId=' + projectId)
  }

  public getRouteStationsSpeedWithFilterDownload(id: number, dateFrom: string, dateTo: string, minutes: number, deKanal: number, projectId: number, filter: string): Observable<any> {
    return this.httpService.getBlob('report/' + id + '/SpeedReport/' + dateFrom + '/' + dateTo + '?minutes=' + minutes + '&deKanal=' + deKanal + '&projectId=' + projectId + '&filter=' + filter)
  }

  public getAQReport(samId: number, filter: ChangelogAqFilter): Observable<any> {
    return this.httpService.post<any>('report/' + samId + '/AQReport', filter)
  }

  public getAQReportDownload(samId: number, filter: ChangelogAqFilter): Observable<any> {
    return this.httpService.postBlob('report/' + samId + '/AQReport', filter)
  }
}
