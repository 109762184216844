<div>
    <div style="display: flex; flex-direction: row;">
        <div class="col-1 text-danger fw-semibold border-end border-bottom border-secondary ps-1">SamID</div>
        <div class="col-1 text-danger fw-semibold border-end border-bottom border-secondary ps-1">Online</div>
        <div class="col-3 text-danger fw-semibold border-end border-bottom border-secondary ps-1">Zeitpunkt</div>
        <div class="col-2 text-danger fw-semibold border-end border-bottom border-secondary ps-1">z</div>
        <div class="col-1 text-danger fw-semibold border-end border-bottom border-secondary ps-1">q</div>
        <div class="col-1 text-danger fw-semibold border-end border-bottom border-secondary ps-1">km/h</div>
        <div class="col text-danger fw-semibold border-bottom border-secondary ps-1">Dauer</div>
    </div>
    <div *ngFor="let changelog of limitedChangelogs"
        style="display: flex; flex-direction: row; border-bottom: 1px solid lightgray;">
        <div class="col-1 border-end ps-1">{{changelog.samId}}</div>
        <div class="col-1 border-end ps-1">{{changelog.online}}</div>
        <div class="col-3 border-end ps-1">{{changelog.dateTime}}</div>
        <div class="col-2 border-end ps-1">{{changelog.z}}</div>
        <div class="col-1 border-end ps-1">{{changelog.q}}</div>
        <div class="col-1 border-end ps-1">{{changelog.kfzSmoothedAverageSpeed}}</div>
        <div class="col ps-1">{{changelog.duration}}</div>
    </div>
</div>
<div style="height: 40px;"></div>
<div class="position-absolute" style="bottom: 0; right: 10px;">
    <div class="m-2 d-flex">
        <div class="d-flex">
            <avs-text-input [value]="rowQuantity" [classInputOnly]="'form-control-sm'"
                (valueChange)="onRowQuantityChanged(+$event)" [type]="'number'" style="width: 50px;">
            </avs-text-input>
            <div class="text-secondary mt-1 ms-1">Zeilen<span *ngIf="changelogs.length > 0"> von <span
                        class="text-danger">{{changelogs.length}}</span></span></div>
        </div>
        <div class="ms-3">
            <img src="assets/icons/chevron-double-left.svg" alt="double left Icon" role="button" width="24"
                class="avs-changelog-table-paginator-arrow" (click)="onDoubleLeftIconClicked()">
            <img src="assets/icons/chevron-left-grey.svg" alt="left Icon" width="24" (click)="onLeftIconClicked()"
                role="button" class="avs-changelog-table-paginator-arrow">
            <span class="text-secondary mx-2">
                <span class="text-danger">{{rowRange.start}}</span> -
                <span class="text-danger">{{rowRange.end}}</span>
            </span>
            <img src="assets/icons/chevron-right-grey.svg" alt="right left Icon" width="24"
                (click)="onRightIconClicked()" role="button" class="avs-changelog-table-paginator-arrow">
            <img src="assets/icons/chevron-double-right.svg" alt="double right Icon" width="24"
                class="avs-changelog-table-paginator-arrow" role="button" (click)="onDoubleRightIconClicked()">
        </div>
    </div>
</div>