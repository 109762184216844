import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getCustomstellcodeSelect } from 'src/app/project/shared/enums/custom-stellcodes.enum';
import { AnimatedImages } from 'src/app/project/shared/interfaces/animated-images';
import { ProjectGroup } from 'src/app/project/shared/interfaces/project-group.interface';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'avs-animated-image-modal',
  templateUrl: './animated-image-modal.component.html',
  styleUrls: ['./animated-image-modal.component.css']
})
export class AnimatedImageModalComponent implements OnInit {

  public activeProject?: Project;
  public allImages: ProjectImageData[] = [];
  public filteredImages: ProjectImageData[] = [];
  public assignedImages: ProjectImageData[] = [];
  public animatedImages: AnimatedImages[] = [];
  public position: number = 0;
  public stellcode: any = 0;
  public confirmationsDialogBoolean: boolean = false;
  public isFunctionDropdownOpen: boolean = false;
  public nextOpenImageId: number = 0;

  public selectStellcode: SelectInput = { name: "201", id: 122 };
  public stellcodeSelect: SelectInput[] = getCustomstellcodeSelect()

  public isGroupDropdownOpen: boolean = false;
  public groupSelect: SelectInput[] = [];
  public selectedGroup: SelectInput = { name: "Gruppe", id: 0};

  protected readonly environment = environment;
  constructor(private dialogRef: MatDialogRef<AnimatedImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ngOnInit(): void {
    this.allImages = this.data.projectImages;
    this.filteredImages = this.data.notAssignedImages;
    this.assignedImages = this.data.assignedImages;
    var stellcodeFilteredById = this.stellcodeSelect.find((e) => e.id === this.nextOpenImageId)
    if (stellcodeFilteredById != undefined) { this.selectStellcode.name = stellcodeFilteredById.name; this.selectStellcode.id = stellcodeFilteredById.id }
    this.getSelectInputClass();
    this.onFunctionItemClicked(this.selectStellcode);

    this.activeProject = this.data.data;
    this.getGroupSelect()
  }

  public getGroupSelect() {
    this.activeProject!.groups.forEach((element: ProjectGroup) => {
      this.groupSelect?.push({ name: element.name, id: element.id })
    })
  }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  public onSubmitModalClicked(): void {
    this.animatedImages.forEach((x, index) => { x.position = index + 1 })
    this.dialogRef.close({ animatedImages: this.animatedImages, stellcode: this.stellcode });
  }

  public getPathByImageId(imageId: number) {
    let imageObject = this.assignedImages.find((x) => x.imageId == imageId)
    return imageObject?.path as string
  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectStellcode.id = item.id;
    this.selectStellcode.name = item.name
    this.stellcode = item.name;
  }

  public onGroupDropdownClicked(): void {
    this.isGroupDropdownOpen = !this.isGroupDropdownOpen;
  }

  public onGroupItemClicked(item: SelectInput): void {
    this.isGroupDropdownOpen = false;
    this.selectedGroup.id = item.id;
    this.selectedGroup.name = item.name
  }

  public onProjectImageClicked(image: ProjectImageData) {
    this.position = this.position + 1;
    this.animatedImages.push({ imageId: image.imageId, duration: 2000 })
  }

  public onRemoveImageClicked(index: number) {
    this.animatedImages.splice(index, 1)
  }

  public onGetSelectedClass(): string {
    if (this.filteredImages !== undefined) {
      if (this.filteredImages.find(fi => fi.imageId == this.selectStellcode.id)) {
        this.confirmationsDialogBoolean = true
        return 'fw-bolder text-success';
      } else {
        this.confirmationsDialogBoolean = false
        return 'fw-bolder text-danger';
      }
    } return ''
  }

  private getSelectInputClass(): void {
    this.stellcodeSelect.forEach(item => {
      if (this.filteredImages.find(fi => fi.imageId === item.id)) {
        item.class = 'fw-bolder text-success';
      } else {
        item.class = 'fw-bolder text-danger';
      }
    });
  }
}