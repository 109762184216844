<ng-container *ngIf="activeGroup">
    <div class="row">
        <div class="col-xl-6 mt-3">
            <div class="card rounded-0 shadow p-3 h-100">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <p class="fw-semibold text-secondary"> Meta </p><span class="ms-2 text-secondary">Id:
                            {{activeGroup.id}}</span>
                    </div>
                    <mat-slide-toggle [checked]="activeGroup.isArchived" color="primary"
                        (change)="onArchiveGroupChange()">
                        Archivieren
                    </mat-slide-toggle>

                    <mat-slide-toggle [checked]="activeGroup.isActive" color="primary" (change)="onActiveGroupChange()">
                        Aktiv
                    </mat-slide-toggle>
                </div>
                <avs-text-input label="Name" (valueChange)="onMetaValueChange($event, GroupMetaDataTypes.name)"
                    [value]="activeGroup.name" placeholder="Gruppen Name...">
                </avs-text-input>
                <avs-text-input class="mt-3" label="Straße"
                    (valueChange)="onMetaValueChange($event, GroupMetaDataTypes.routeName)"
                    [value]="activeGroup.routeName" placeholder="Straße eingeben...">
                </avs-text-input>
                <div class="d-flex mt-3">

                </div>

                <button class="btn btn-outline-success mt-auto rounded-0" type="button" *ngIf="groupMetaData"
                    (click)="onSaveMetaDataClicked()">
                    <span>Speichern</span>
                </button>

            </div>
        </div>
        <div class="col-xl-6 mt-3">
            <div class="card rounded-0 shadow h-100">
                <div class="placeholder-map-group"></div>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 mt-3">
            <div class="card rounded-0 shadow h-100 p-3">
                <div class="d-flex justify-content-between">
                    <p class="fw-semibold text-secondary">Stationen</p>
                    <img class="avs-icon-add mb-3" src="assets/icons/plus.svg" alt="icon-add-station"
                        (click)="onAddStationBtnClicked()">
                </div>
                <div *ngIf="routeStations && routeStations.length > 0; else noRouteStations" class="border-2 border">
                    <ng-container *ngFor="let routeStation of routeStations">
                        <div class="m-2 border-bottom border-1 d-flex justify-content-between">
                            <span class="p-1 avs-station-list-item w-100 justify-content-between d-flex"
                                (click)="onStationClicked(routeStation)">
                                <span>{{routeStation.name}}</span>
                                <span class="ms-2 text-secondary">Sam ID: {{routeStation.samId}}</span>
                            </span>
                            <div>
                                <img class="avs-icon-delete p-1" src="assets/icons/trash.svg" alt="icon-delete-group"
                                    (click)="onDeleteRouteStationClicked(routeStation)">
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-template #noRouteStations>
                    <p class="mt-3 fw-semibold text-secondary">Keine Stationen angelegt</p>
                </ng-template>
            </div>
        </div>
        <div class="col-xl-6 mt-3">
            <div class="card rounded-0 shadow h-100 p-3">
                <div class="d-flex justify-content-between">
                    <p class="text-secondary fw-semibold ms-3">Ort</p>
                    <div>
                        <a href="https://maps.google.com/?q={{activeGroup.latitude ? activeGroup.latitude : 0}},{{activeGroup.longitude ? activeGroup.longitude : 0}}"
                            target="_blank" class="btn">
                            <img src="../../../../assets/icons/pin-map-fill.svg" alt="Open" class="avs-icon-edit p-2"
                                height="36"></a>
                        <button class="btn" (click)="onCopyCoordinatesLinkBtnClick()"><img class="avs-icon-edit p-2"
                                height="36" src="../../../../assets/icons/copy.svg" alt="Open"></button>
                    </div>
                </div>
                <div class="row">
                    <avs-text-input class="col-xxl-6 mb-2" label="Latitude"
                        [value]="activeGroup.latitude ? activeGroup.latitude.toLocaleString('en-EN', {minimumFractionDigits: 14}) : '0'"
                        placeholder="latitude..." (valueChange)="onGroupPositionChange($event, true)"></avs-text-input>
                    <avs-text-input class="col-xxl-6" label="Longitude"
                        [value]="activeGroup.longitude ? activeGroup.longitude.toLocaleString('en-EN', {minimumFractionDigits: 14}) : '0'"
                        placeholder="longitude" (valueChange)="onGroupPositionChange($event, false)"></avs-text-input>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-outline-success mt-3 rounded-0" type="button" *ngIf="groupPositionData"
                            (click)="onSavePositionDataClicked()">
                            <span>Speichern</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 mt-3">
            <div class="card rounded-0 shadow h-100 p-3">
                <div class="d-flex justify-content-between">
                    <p class="text-secondary fw-semibold">Erweiterte Gruppeneinstellungen</p>
                </div>
                <div class="row d-flex justify-content-between ">
                    <span class="d-flex justify-content-center "> Gruppenstellcode erstellen</span>
                    <div class="w-50 ">

                        <div class="w-100 d-flex justify-content-center mb-2 ">
                            <input type="number" [(ngModel)]="stellcode" class="form-control rounded-0 "
                                style="width: 300px">

                        </div>
                        <div class="w-100 d-flex justify-content-center mb-2">
                            <div class="avs-route-station-settings-dropdown-container justify-content-between">
                                <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''"
                                    class="border d-flex justify-content-between" role="button"
                                    (click)="onFunctionDropdownClicked()">
                                    <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                                    <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                        alt="caret-down open select">
                                    <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                        alt="caret-up close select">
                                </div>
                                <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                                    (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false"
                                    *ngIf="isFunctionDropdownOpen"
                                    class="avs-route-station-settings-dropdown-container">
                                </avs-dropdown>
                            </div>
                        </div>

                        <div class="w-100 d-flex justify-content-center mb-2">
                            <div class="avs-route-station-settings-dropdown-container justify-content-between">
                                <div [ngClass]="isDeKanalGroupStellcodeDropdownOpen ? 'border-danger' : ''"
                                    class="border d-flex justify-content-between" role="button"
                                    (click)="onDeKanalGroupStellcodeDropdownClicked()">
                                    <div class="ms-2 p-1">{{ selectedDeKanalGroupStellcode.name }}</div>
                                    <img *ngIf="!isDeKanalGroupStellcodeDropdownOpen" class="me-2"
                                        src="assets/icons/caret-down.svg" alt="caret-down open select">
                                    <img *ngIf="isDeKanalGroupStellcodeDropdownOpen" class="me-2"
                                        src="assets/icons/caret-up.svg" alt="caret-up close select">
                                </div>
                                <avs-dropdown [selectInput]="deKanalGroupStellcodeSelect"
                                    [activeItem]="selectedDeKanalGroupStellcode"
                                    (onItemClicked)="onDeKanalGroupStellcodeClicked($event)" [isHeaderActive]="false"
                                    *ngIf="isDeKanalGroupStellcodeDropdownOpen"
                                    class="avs-route-station-settings-dropdown-container">
                                </avs-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="justify-content-evenly align-items-center mt-2">
                            <div class="w-75 rounded-2" style="background-color: #d2cdcd96;">
                                <span class="d-flex border-black me-2 ms-2 mt-2 mb-2 text-center pt-3 pb-3"
                                    style="border-radius: 12px">
                                    Bitte stellen Sie sicher, dass alle Anzeigen synchronisiert wurden.
                                </span>
                            </div>
                            <button *ngIf="selectedDeKanalGroupStellcode.id !== undefined"
                                class="w-75 btn btn-outline-success mt-2 rounded-0 " type="button"
                                (click)="setGroupStellcode()">
                                <span>Stellcode für Gruppe setzen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>



</ng-container>