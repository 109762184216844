<div class="position-relative">
  <div class="card rounded-0 mt-3 shadow ">
    <h6 #fullWidth class="m-2">{{activeRouteStation ? 'Streckenstations' : activeGroup ? 'Gruppen' : activeProject ?
      'Projekt' :
      ''}} Einstellungen</h6>
  </div>

  <avs-project-settings-map class="map-default"
  *ngIf="activeProject"
  [style.height.px]="getMapHeight()"
  [style.top.px]="getMapTop()"
  [style.width.px]="isWidthFull ? fullWidth.getBoundingClientRect().width + 16 : fullWidth.getBoundingClientRect().width / 2 - 5"
  ></avs-project-settings-map>

  <ng-container *ngIf="activeProject; else noActiveProject">
    <ng-container *ngIf="activeGroup; else noActiveGroup">
      <ng-container *ngIf="activeRouteStation; else noActiveRouteStation">
        <avs-route-station-settings [activeRouteStation]="activeRouteStation" [dataEndChannels]="dataEndChannels"
          (dataEndChannelRefresh)="onRefreshDataEndChannels()" [dataEndChannelErrors]="dataEndChannelErrors"
          (dataEndChannelErrorsRefresh)="onRefreshDataEndChannelErrors()" [activeGroup]="activeGroup"
          (stationGroupRelationRefresh)="onRefreshStationGroupRelation()" [groupsActiveRouteStation]="projectRouteStationsWithParams" > 
          
        </avs-route-station-settings>
      </ng-container>
      <ng-template #noActiveRouteStation>
        <avs-group-settings [activeGroup]="activeGroup"
          [routeStations]="projectRouteStations? projectRouteStations : []">
        </avs-group-settings>
      </ng-template>
    </ng-container>
    <ng-template #noActiveGroup>
      <avs-project-settings [activeProject]="activeProject" [projectGroups]="projectGroups? projectGroups : []" [activeProjectUsers]="activeProjectsUsers">
      </avs-project-settings>
    </ng-template>
  </ng-container>
  <ng-template #noActiveProject>
    <p class="mt-3 fw-semibold text-secondary">Kein Projekt ausgewählt!</p>
  </ng-template>
</div>