<mat-dialog-content>
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Solardetails</h5>
        <div class="d-flex">

            <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
                (click)="onCloseClicked()">
        </div>
    </div>
    <div class="d-flex justify-content-around ">
        <div *ngFor="let details of detailObj" class="d-flex flex-column me-4">
            <div class="w-100 d-flex justify-content-between"> <span> DeKanal: </span> <span> {{ details.de}}</span>
            </div>
            <div class="w-100 d-flex justify-content-between"> <span> Ladezustand:</span> <span> {{
                    details.ladezustand}}</span></div>
            <div class="w-100 d-flex justify-content-between"> <span> Kapazität:</span> <span> {{
                    details.kapazitaet}}</span></div>
            <div class="w-100 d-flex justify-content-between"> <span> Solarleistung: </span> <span>{{
                    details.solarleistung}}</span>
            </div>
            <div class="w-100 d-flex justify-content-between"> <span> Batteriespannung:</span> <span> {{
                    details.batteriespannung}}</span></div>
            <div class="w-100 d-flex justify-content-between"> <span> Batteriestrom:</span> <span> {{
                    details.batteriestrom}}</span>
            </div>
            <div class="w-100 d-flex justify-content-between"> <span> Lastspannung:</span> <span> {{
                    details.lastspannung}}</span>
            </div>
            <div class="w-100 d-flex justify-content-between"> <span> Laststrom: </span> <span>{{
                    details.laststrom}}</span></div>
            <div class="w-100 d-flex justify-content-between"> <span> Fehlerzustand:</span> <span> {{
                    details.fehlerzustand}}</span>
            </div>
            <div class="w-100 d-flex justify-content-between"> <span> Warnzustand:</span> <span> {{
                    details.warnzustand}}</span></div>
            <div class="w-100 d-flex justify-content-between"><span>
                    Reglertyp:</span> <span> {{
                    details.reglertyp}}</span></div>
            <div class="w-100 d-flex justify-content-between"><span> Status: </span> <span>{{ details.status}}</span>
            </div>
            <div class="w-100 d-flex justify-content-between"><span> Uhrzeit: </span> <span>{{ details.dateTime | date:
                    'dd.MM.yyyy - H:mm': offset(details.dateTime)}}</span>
            </div>

        </div>
    </div>
</mat-dialog-content>