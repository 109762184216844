import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Radar } from 'src/app/device/shared/interfaces/radar.interface';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { SolarDetailedData } from 'src/app/project/shared/interfaces/solar-detailed-data.interface';

@Component({
  selector: 'avs-solar-details',
  templateUrl: './solar-details.component.html',
  styleUrls: ['./solar-details.component.css']
})
export class SolarDetailsComponent implements OnInit {
  public samId?: number;
  public detailObj: SolarDetailedData[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<SolarDetailsComponent>
  ) { }

  public ngOnInit(): void {
    this.samId = this.data.data.routeStation.samId;
    if (this.samId) {
      this.deviceService.getSolarDetailsBySamIdForNewVersion(this.samId).subscribe(element => {
        this.detailObj = element;


      })
    }


  }
  lastSunday(year: number, month: number) {
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);
    return date;
  }

  isCEST(d: Date): boolean {

    let yearString = d as unknown as number;
    let startCET: Date = this.lastSunday(yearString, 3);
    let endCET: Date = this.lastSunday(yearString, 10);

    return !(startCET < d && d < endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }
  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}
