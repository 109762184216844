<span class="d-flex justify-content-center avs-login-page pt-5">
  <span class="col-lg-4 col-xxl-3">
    <span class="d-flex justify-content-center mt-5">
      <img class="avs-login-logo" src="assets/logo/avs.svg">
    </span>
    <div class="card shadow rounded-0 mt-3">
      <div class="card-body">
        <form novalidate (ngSubmit)="onLoginButtonClicked()" #form="ngForm">
          <div class="form-group">
            <label class="ms-2" for="inputEmail">E-Mail</label>
            <input type="email" class="form-control rounded-0 mt-1" id="inputEmail" name="email"
                   aria-describedby="email input" placeholder="E-Mail eingeben..."
                   [(ngModel)]="loginData.email" #email="ngModel" required>
            <span *ngIf="!email.control.valid && email.touched" class="text-danger">
              Bitte E-Mail Adresse eingeben!
            </span>
          </div>
          <div class="form-group">
            <label class="ms-2 mt-2" for="inputPassword">Passwort</label>
            <input type="password" class="form-control rounded-0 mt-1" id="inputPassword" name="password"
                   placeholder="Passwort eingeben..." [(ngModel)]="loginData.password" required
                   #password="ngModel">
            <span *ngIf="!password.control.valid && password.touched" class="text-danger">
              Bitte Passwort eingeben!
            </span>
          </div>
          <div class="d-flex justify-content-between">
            <a class="link-secondary mt-4 ms-1" role="button" (click)="onPasswordForgotClicked()">
              Passwort vergessen?
            </a>
            <button class="btn btn-secondary rounded-0 mt-3" [disabled]="!form.valid" type="submit">Log in</button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="isLoading" class="spinner-border text-danger float-end mt-2" role="status"></div>
  </span>
</span>
