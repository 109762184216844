<div class="mt-2">
    <!-- Tab einbauen + Karte -->

    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Tabelle">

            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource!" matSort>

                    <ng-container matColumnDef="imei">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> imei </th>
                        <td mat-cell *matCellDef="let row"> {{row.imei}} </td>
                    </ng-container>

                    <ng-container matColumnDef="timeStamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Uhrzeit </th>
                        <td mat-cell *matCellDef="let row"> {{row.timeStamp | date: 'dd.MM.yyyy - H:mm':
                            offset(row.timeStamp)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="voltage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Spannung </th>
                        <td mat-cell *matCellDef="let row"> {{row.voltage}} </td>
                    </ng-container>

                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> message </th>
                        <td mat-cell *matCellDef="let row"> {{row.message}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lat">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> GPS (lat|lon) </th>
                        <td mat-cell *matCellDef="let row"> {{row.lat}} | {{ row.lon}}</td>
                    </ng-container>

                    <ng-container matColumnDef="roll">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> roll </th>
                        <td mat-cell *matCellDef="let row"> {{row.roll}}</td>
                    </ng-container>

                    <ng-container matColumnDef="pitch">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> pitch </th>
                        <td mat-cell *matCellDef="let row"> {{row.pitch}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cycles">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> cycles </th>
                        <td mat-cell *matCellDef="let row"> {{row.cycles}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab label="Karte">
            <div>
                <avs-lisa-gmap [pins]="messageCollection"></avs-lisa-gmap>
            </div>
        </mat-tab>

    </mat-tab-group>





</div>