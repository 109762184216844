import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { Observable } from "rxjs";
import { ChangelogDeviceFilter } from "../interfaces/changelog-device-filter.interface";
import { ChangelogGroupFilter } from "../interfaces/changelog-group-filter.interface";
import { ChangelogProjectFilter } from "../interfaces/changelog-project-filter.interface";
import { Changelog } from "../interfaces/changelog.interface";

@Injectable({
    providedIn: 'root'
})
export class EngineService {

    constructor(private readonly httpService: HttpService) { }

    public getChangelogDevice(deviceFilter: ChangelogDeviceFilter): Observable<Changelog[]> {
        return this.httpService.post<Changelog[]>('Engine/Changelog/device', deviceFilter);
    }

    public getChangelogGroup(groupFilter: ChangelogGroupFilter): Observable<Changelog[]> {
        return this.httpService.post<Changelog[]>('Engine/Changelog/group', groupFilter);
    }

    public getChangelogProject(projectFilter: ChangelogProjectFilter): Observable<Changelog[]> {
        return this.httpService.post<Changelog[]>('Engine/Changelog/project', projectFilter);
    }

    public getChangelogGroupDownload(groupFilter: ChangelogGroupFilter): Observable<Blob> {
        return this.httpService.postBlob('Engine/Changelog/group', groupFilter);
    }

    public getChangelogProjectDownload(projectFilter: ChangelogProjectFilter): Observable<Blob> {
        return this.httpService.postBlob('Engine/Changelog/project', projectFilter);
    }

    public getChangelogRouteStationDownload(deviceFilter: ChangelogDeviceFilter): Observable<Blob> {
        return this.httpService.postBlob('Engine/Changelog/device', deviceFilter);
    }

}
