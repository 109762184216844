import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataPointService } from 'src/app/project/shared/services/datapoint.service';
import { Chart } from 'chart.js/auto';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';

@Component({
  selector: 'avs-speed-chart',
  templateUrl: './speed-chart.component.html',
  styleUrls: ['./speed-chart.component.css']
})

export class SpeedChartComponent {
  public chart: any;
  public samId: number;
  public now = new Date();
  public nowAsISOString: string;
  public twoDaysAgoAsString: string;
  public chartData: any;
  public labelCollection: string[] = [];
  public resultCollection: number[] = [];

  public lkwAverageSpeedCollection: number[] = [];
  public pkwAverageSpeedCollection: number[] = [];
  public kfzTrafficVolumeCollection: number[] = [];
  public lkwTrafficVolumeCollection: number[] = [];
  public pkwTrafficVolumeCollection: number[] = [];

  public isChannelDropdownOpen: boolean = false;
  public channelSelect: SelectInput[] = [{ name: 'Kanal 1', id: 1 }];
  public selectedChannel: SelectInput = { name: 'Kanal 1', id: 1 };

  public isIntervallDropdownOpen: boolean = false;
  public intervallSelect: SelectInput[] = [{ name: '5 Minuten', id: 5 }, { name: '15 Minuten', id: 15 }, { name: '30 Minuten', id: 30 }, { name: '60 Minuten', id: 60 }];
  public selectedIntervall: SelectInput = { name: '5 Minuten', id: 5 };

  public fromDate: number = 2;
  public toDate: number = 0;
  public selectedMinutes: number = 60;
  public minutes: number[] = [5, 15, 30, 60];
  public selectedKanal: number = 1;
  public kanals: number[] = [1]; /* restliche auslesen */

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dataPointService: DataPointService,
    private reportService: ReportService,
    private dialogRef: MatDialogRef<SpeedChartComponent>) {
    this.samId = this.data.data.routeStation.samId
    this.nowAsISOString = this.now.toISOString()
    let d = new Date();
    d.setDate(d.getDate() - 2);
    this.twoDaysAgoAsString = d.toISOString()
    if (this.data.data.deviceParameter.radar.length > 1) {
      let length = this.data.data.deviceParameter.radar.length /* die condition kann ich als disabled für den kanal dropdown nutzen */
      for (let i = 2; i <= length; i++) {
        this.channelSelect.push({ name: 'Kanal ' + i, id: i })
      }
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  public exportChart(): void {
    let canvas = document.getElementById('MyChart') as HTMLCanvasElement;
    let dataURL = canvas.toDataURL('image/png');
    let link = document.createElement('a');
    link.href = dataURL;
    link.download = 'chart.png';
    link.click();
  }

  private getData(): void {
    this.labelCollection = [];
    this.resultCollection = [];
    this.lkwAverageSpeedCollection = [];
    this.pkwAverageSpeedCollection = [];
    this.kfzTrafficVolumeCollection = [];
    this.lkwTrafficVolumeCollection = [];
    this.pkwTrafficVolumeCollection = [];

    this.reportService.getRouteStationsSpeedWithFilter(this.samId, this.twoDaysAgoAsString, this.nowAsISOString, Number(this.selectedIntervall.id), Number(this.selectedChannel.id), this.data.data.routeStation.groupId).subscribe(x => {
      this.chartData = x;
      this.chartData.forEach((element: { measuredAt: string, lkwAverageSpeed: number, pkwAverageSpeed: number, kfzTrafficVolume: number, lkwTrafficVolume: number, pkwTrafficVolume: number }) => {

        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection.push(dateTime)
      });
      this.chartData.forEach((element: { lkwAverageSpeed: number, pkwAverageSpeed: number, kfzTrafficVolume: number, lkwTrafficVolume: number, pkwTrafficVolume: number }) => {

        this.lkwAverageSpeedCollection.push(element.lkwAverageSpeed)
        this.pkwAverageSpeedCollection.push(element.pkwAverageSpeed)
        this.kfzTrafficVolumeCollection.push(element.kfzTrafficVolume)
        this.lkwTrafficVolumeCollection.push(element.lkwTrafficVolume)
        this.pkwTrafficVolumeCollection.push(element.pkwTrafficVolume)

      });
      this.createChart();
    })
  }

  public onChannelItemClicked(item: SelectInput): void {
    this.isChannelDropdownOpen = false;
    this.selectedChannel.name = item.name
    this.selectedChannel.id = item.id
  }

  public onChannelDropdownClicked() {
    this.isChannelDropdownOpen = !this.isChannelDropdownOpen;
  }

  public onIntervallItemClicked(item: SelectInput): void {
    this.isIntervallDropdownOpen = false;
    this.selectedIntervall.name = item.name
    this.selectedIntervall.id = item.id
  }

  public onIntervallDropdownClicked() {
    this.isIntervallDropdownOpen = !this.isIntervallDropdownOpen;
  }

  public removeData(chart: any): void {
    chart.destroy()
    this.getData();
  }

  public setFromDate(date: number): void {
    this.fromDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.fromDate);
    this.twoDaysAgoAsString = d.toISOString()
  }

  public setToDate(date: number): void {
    this.toDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.toDate);
    this.nowAsISOString = d.toISOString()
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  private createChart(): void {
    this.chart = new Chart("MyChart", {
      type: 'line',

      data: {
        labels: this.labelCollection,
        datasets: [
          {
            label: "Durchschnittsgeschiwndigkeit LKW in km/h",
            data: this.lkwAverageSpeedCollection,
            backgroundColor: 'blue',
            fill: false,
            borderColor: 'rgba(0, 0, 255, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Durchschnittsgeschwindigkeit PKW in km/h",
            data: this.pkwAverageSpeedCollection,
            backgroundColor: 'red',
            fill: false,
            borderColor: 'rgba(255, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Anzahl KFZ",
            data: this.kfzTrafficVolumeCollection,
            backgroundColor: 'green',
            fill: false,
            borderColor: 'rgba(0, 255, 255, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Anzahl LKW",
            data: this.lkwTrafficVolumeCollection,
            backgroundColor: 'yellow',
            fill: false,
            borderColor: 'rgba(0, 255, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Anzahl PKW",
            data: this.pkwTrafficVolumeCollection,
            backgroundColor: 'black',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },


        ]
      },
      options: {
        aspectRatio: 2.5,

      },
      plugins: [
        {
          id: 'customCanvasBackgroundColor',
          beforeDraw: function (chart: any) {
            const ctx = chart.ctx;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        }
      ]

    });
  }
}
