<div class="avs-route-station-container position-relative">
    <div class="avs-route-station-header">
        <div class="d-flex justify-content-center">
            <span class="avs-route-station-label m-1 ps-2 pe-2">{{routeStation?.routeStation?.name}}</span>
        </div>
        <div class="d-flex justify-content-center">
            <span class="m-1 text-secondary">SAM ID: {{routeStation?.routeStation?.samId}}</span>
            <div class="ms-2" *ngIf="routeStation?.deviceParameter?.blinker?.vorwarnererror?.error">
                <img height="26" alt="rotes WarnIcon" src="assets/icons/exclamation-triangle.svg">
            </div>
        </div>
        <span class="text-secondary d-flex justify-content-center " *ngIf="!routeStation?.deviceParameter?.online">
        </span>
    </div>
    <div class="d-flex">
        <div class="avs-route-station-site-area"
            [ngClass]="routeStation?.deviceParameter?.led?.channelError ? 'extra-margin' : ''">
            <ng-container *ngIf="routeStation?.deviceParameter?.online">
                <ng-container
                    *ngIf="(routeStation?.deviceParameter?.typ === DeviceTypeEnum.AQ || routeStation?.deviceParameter?.typ === DeviceTypeEnum.Mixed) && !checkIfPW(routeStation!)">
                    <!-- Zeile nochmal genauer prüfen, muss ggf erweitert werden, war im solarpanel auskommentiert und hat zu UI-fehlern geführt -->
                    <div class="d-flex justify-content-center">
                        <img height="36" alt="Icon for LED Brightness" src="assets/icons/brightness-alt-high.svg"
                            [ngClass]="routeStation?.deviceParameter?.led?.channelError ? 'led-error-small' : ''"><!-- TODO: (3) erweiterung, um warnung - gelber Rahmen wenn lediglich eine Warnung vorliegt -->
                    </div>
                    <div [ngClass]="routeStation?.deviceParameter?.online ? '' : 'text-secondary'"
                        class="text-center small" style="margin-top: -10px;"
                        [ngClass]="routeStation?.deviceParameter?.led?.channelError ? 'd-none' : ''">
                        {{routeStation?.deviceParameter?.led?.helligkeitswert}}
                    </div>
                </ng-container>
                <div [ngClass]="routeStation?.deviceParameter?.solar?.channelError ? 'led-error-small' : ''">
                    <div class="d-flex justify-content-center"
                        [class]="routeStation?.deviceParameter?.typ === DeviceTypeEnum.MQ || checkIfPW(routeStation!) ? 'mt-4' : ''">
                        <img *ngIf="isModalOpen" height="36" alt="Batterie Icon nach Akku Spannung"
                            [src]="getBatteryIcon()"
                            matTooltip=" {{ routeStation?.deviceParameter?.solar?.dateTime | date: 'dd.MM.yyyy H:mm'}}"
                            (click)="openVoltageChart()" class="icon-button">
                        <img *ngIf="!isModalOpen" height="36" alt="Batterie Icon nach Akku Spannung"
                            [src]="getBatteryIcon()"
                            matTooltip=" {{ routeStation?.deviceParameter?.solar?.dateTime | date: 'dd.MM.yyyy H:mm'}}"><!-- TODO: weitere Infos, z.B. anzahl solarpanele, da nicht mehr präsent -->
                    </div>
                    <div [ngClass]="routeStation?.deviceParameter?.online ? '' : 'text-secondary'"
                        class="text-center small" style="margin-top: -10px;">
                        {{getBatterySpannung(routeStation?.deviceParameter)}}
                    </div>
                    <div>
                        <div class="d-flex justify-content-center">
                            <div class="position-relative">
                                <img height="36" alt="Batterie Icon Ladestrom"
                                    src="assets/icons/battery/battery_very_low_FILL0_wght400_GRAD0_opsz24.svg">
                                <img class="position-absolute avs-route-station-arrow-battery" height="20"
                                    alt="Pfeil rechts -> batterie laden"
                                    [src]="getIconForPowerPurchase(routeStation?.deviceParameter)">
                            </div>
                        </div>
                        <div [class]="getBatteryPowerPurchaseClass(routeStation?.deviceParameter)"
                            style="margin-top: -10px;">
                            {{getBatteryPowerPurchase(routeStation?.deviceParameter)}}
                        </div>
                    </div>
                    <div>
                        <div class="d-flex justify-content-center">
                            <div class="position-relative">
                                <img *ngIf="isModalOpen" height="20" alt="Stromverbrauch Icon"
                                    src="../../../assets/icons/stromverbrauch_blitz_pfeil.svg"
                                    (click)="openCurrentChart()" class="icon-button">
                                <img *ngIf="!isModalOpen" height="20" alt="Stromverbrauch Icon"
                                    src="../../../assets/icons/stromverbrauch_blitz_pfeil.svg">
                            </div>
                        </div>
                        <div class="text-center small text-danger" style="margin-top: -3px;">

                            {{getBatteryChargingCurrent(routeStation?.deviceParameter) }}
                        </div>
                    </div>


                    <!-- localbus 10, fg 6, kanal 12 + 13 als Conditions, jeweils 1 Feld
 in kanal 12 "1" reinschreiben, in die 13 "2" -->


                    <div *ngIf="isModalOpen">
                    <div class="d-flex justify-content-center mt-1">
                        <div *ngFor="let channel of routeStation?.routeStation?.dataEndChannels">
                            <div *ngIf="channel.localbusAddress === 10 && channel.de === 11 && solarChannelCount !== 5"
                                style="width: 15px; "
                                [ngClass]="routeStation?.deviceParameter?.solar?.panelError1   ? 'channel-error' : 'no-channel-error'"
                                class="m-1 text-center">1</div>
                            <!-- erweiterung, um warnung - gelber Rahmen wenn lediglich eine Warnung vorliegt -->
                            <div *ngIf="channel.localbusAddress === 10 && channel.de === 12 && solarChannelCount !== 5"
                                style="width: 15px;"  [ngClass]="routeStation?.deviceParameter?.solar?.panelError2   ? 'channel-error' : 'no-channel-error'"
                                class="m-1 text-center">2</div>
                            <!-- erweiterung, um warnung - gelber Rahmen wenn lediglich eine Warnung vorliegt -->
                        </div>
                        <!-- TODO: fehler generieren, färbung der panele prüfen, auch hier warn farbe implementieren -->
                    </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!routeStation?.deviceParameter?.online" class="text-center mt-4"> <img height="26"
                    alt="Letze gemessene Akku Spannung" src="../../../assets/icons/cloud-lightning.svg"
                    [ngClass]="routeStation?.deviceParameter?.solar?.channelError ? 'led-error-small' : ''"><!-- erweiterung, um warnung - gelber Rahmen wenn lediglich eine Warnung vorliegt -->
                <br>
                {{routeStation?.routeStation?.latestVoltage?.voltage ?
                routeStation?.routeStation?.latestVoltage?.voltage + 'V' : 'N/A'}}
            </div>
        </div>
        <div class="avs-route-station-middle-area">
            <ng-container *ngIf="deviceType !== DeviceTypeEnum.MQ; else radar">
                <div class="d-flex justify-content-between">
                    <div class="rounded-5 border"
                        [ngClass]="routeStation?.deviceParameter?.blinker?.channelError ? 'border-danger border-2' : 'border-black'">
                        <div *ngIf="routeStation?.deviceParameter?.blinker?.stellcode === 61 && routeStation?.deviceParameter?.blinker?.indicator === 'einschalten' && routeStation?.deviceParameter?.online && !routeStation?.deviceParameter?.blinker?.channelError; else blinkerDeaktive"
                            class="bg-warning p-3 rounded-5" [@blink]="blinkState">
                        </div>
                        <ng-template #blinkerDeaktive>
                            <div *ngIf="!routeStation?.deviceParameter?.blinker?.channelError"
                                class="bg-white p-3 rounded-5"></div>
                            <div *ngIf="routeStation?.deviceParameter?.blinker?.channelError"
                                class="bg-white p-3 rounded-5 radar-error"></div>
                        </ng-template>
                    </div>
                    <div class="rounded-5 border"
                        [ngClass]="routeStation?.deviceParameter?.blinker?.channelError ? 'border-danger border-2' : 'border-black'">
                        <div *ngIf="routeStation?.deviceParameter?.blinker?.stellcode === 61 && routeStation?.deviceParameter?.blinker?.indicator === 'einschalten' && routeStation?.deviceParameter?.online && !routeStation?.deviceParameter?.blinker?.channelError; else blinkerDeaktive"
                            class="bg-warning p-3 rounded-5" [@blink]="blinkState">
                        </div>
                        <ng-template #blinkerDeaktive>
                            <div *ngIf="!routeStation?.deviceParameter?.blinker?.channelError"
                                class="bg-white p-3 rounded-5"></div>
                        </ng-template>
                    </div>
                </div>
                <div [ngClass]="routeStation?.deviceParameter?.online ? 'bg-black' : 'bg-secondary'"
                    class="card rounded-0 shadow avs-route-station-panel p-2 position-relative">
                    <img *ngIf="routeStation?.deviceParameter?.online && routeStation?.deviceParameter?.led?.indicator === 'einschalten' && routeStation?.deviceParameter?.led?.stellcode"
                        [src]="environment.apiAddress + getStationImage(routeStation?.deviceParameter?.led?.stellcode)"
                        alt="Bild wurde nicht gefunden"> <img *ngIf="routeStation?.deviceParameter?.led?.isAnimated"
                        src="assets/icons/shuffle.svg" alt="animatedImage"
                        class="position-absolute bg-white rounded-5 p-1 " style="top: 2px; right: 2px; height: 20px">
                    <div *ngIf="routeStation?.deviceParameter?.led?.channelError" class="led-error"
                        style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"></div>
                    <img *ngIf="routeStation?.deviceParameter?.online && routeStation?.deviceParameter?.led?.indicator === 'ausschalten'"
                        src="assets/led_default.svg"
                        style="height: 184px; width: 112px; position:absolute; top: -22px; left: -7px;">
                    <div *ngIf="routeStation?.deviceParameter?.typ === 0" class="text-danger text-center"
                        style="font-size: 64px;">?</div>
                </div>
                <div *ngIf="!routeStation?.deviceParameter?.online && routeStation?.deviceParameter?.led?.indicator === 'ausschalten'"
                    class="border-bottom border-2 border-black"></div>
                <div *ngIf="!routeStation?.deviceParameter?.online && routeStation?.deviceParameter?.led?.indicator === 'ausschalten'"
                    class="avs-route-station-panel-bottom"></div>
            </ng-container>
            <ng-template #radar>
                <div class="d-flex justify-content-center bg-light ms-2 me-2 border border-2 border-secondary mt-3">
                    <img height="70" src="assets/icons/data-end-channel/radar_aq.svg">
                </div>
            </ng-template>
        </div>
        <div class="avs-route-station-site-area">
            <div *ngIf="routeStation?.deviceParameter" class="d-flex justify-content-center mb-2">
                <img *ngIf="routeStation!.deviceParameter.sim.signal !== 0"
                    [matTooltip]="routeStation?.deviceParameter?.sim?.provider ? routeStation!.deviceParameter.sim.provider : ''"
                    matTooltipPosition="above" height="26" alt="Signal Icon nach Empfangsstärke"
                    [src]="getReceptionIcon()">
            </div>
            <ng-container *ngFor="let radar of routeStation?.deviceParameter?.radar">
                <div *ngIf="isModalOpen" class="d-flex justify-content-center icon-button"
                    (click)="openRadarDataChart(routeStation?.deviceParameter?.radar)">
                    <img *ngIf="!radar.channelError" height="26" alt="Radar Icon"
                        src="assets/icons/data-end-channel/radar.svg"> <span *ngIf="!radar.channelError"
                        class="text-secondary " style="font-size: 12px"> {{radar.de}}</span>
                    <img *ngIf="radar.channelError" height="26" alt="Radar Icon" class="radar-error"
                        src="assets/icons/data-end-channel/radar.svg"> <span *ngIf="radar.channelError"
                        class="text-secondary " style="font-size: 12px"> {{radar.de}} </span>
                </div>
                <div *ngIf="!isModalOpen" class="d-flex justify-content-center">
                    <img *ngIf="!radar.channelError" height="26" alt="Radar Icon"
                        src="assets/icons/data-end-channel/radar.svg"> <span *ngIf="!radar.channelError"
                        class="text-secondary " style="font-size: 12px"> {{radar.de}}</span>
                    <img *ngIf="radar.channelError" height="26" alt="Radar Icon" class="radar-error"
                        src="assets/icons/data-end-channel/radar.svg"> <span *ngIf="radar.channelError"
                        class="text-secondary " style="font-size: 12px"> {{radar.de}}</span>
                </div>
                <div class="text-secondary ms-3 small text-nowrap text-center" style="margin-top: -5px">
                    {{radar.kfzSmoothedAverageSpeed}}
                    km/h
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!routeStation?.deviceParameter?.online" class="position-absolute avs-offline-station" matTooltip='{{
        routeStation?.routeStation?.lastData | date : "dd.MM.YYYY H:mm" }}' matTooltipPosition="above"></div>
</div>