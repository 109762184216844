<div class="pt-5">
  <h3 class="text-center">Übersicht</h3>
  <h5 class="text-center">der Streckenstationen</h5>
</div>

<div class="row text-center pt-5 justify-content-center mt-auto">
  <div class="row col-xxl-6 avs-dashboard-row-bottom">
    <div class="col-md-6  mb-3">
      <div class="p-4 card shadow rounded-0 h-100">
        <h6>Zuweisung</h6>
        <div *ngIf="!isChartAssignmentDataZero">
          <canvas id="canvasAssignment" class="avs-dashboard-canvas">{{chart}}</canvas>
        </div>
        <div *ngIf="isChartAssignmentDataZero" class="mt-4">
          <span class="text-danger mt-4">Keine Daten vorhanden</span>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="p-4 card shadow rounded-0 h-100">
        <h6>Auslastung</h6>
        <div *ngIf="!isChartWorkloadDataZero">
          <canvas id="canvasWorkload" class="avs-dashboard-canvas">{{chart}}</canvas>
        </div>
        <div *ngIf="isChartWorkloadDataZero" class="mt-4">
          <span class="text-danger mt-4">Keine Daten vorhanden</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row col-xxl-6 avs-dashboard-row-bottom">
    <div class="col-md-6 mb-3">
      <div class="p-4 card shadow rounded-0 h-100">
        <h6>Fehlerquote</h6>
        <div *ngIf="!isChartErrorRateDataZero">
          <canvas id="canvasErrorRate" class="avs-dashboard-canvas">{{chart}}</canvas>
        </div>
        <div *ngIf="isChartErrorRateDataZero" class="mt-4">
          <span class="text-danger mt-4">Keine Daten vorhanden</span>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="p-4 card shadow rounded-0 h-100">
        <h6>Aktuelle Fehlermeldungen</h6>
        <div>
          <div class="pt-5 px-3 ">
            <p class="pb-0 mb-0 avs-dashboard-error-message-top">271-22-0060 Darmstadt, Neubau Rheinstraßenbrücke</p>
            <p class="avs-dashboard-error-message-bottom">Verbindung unterbrochen</p>
            <hr>
          </div>
          <div class="px-3 ">
            <p class="pb-0 mb-0 avs-dashboard-error-message-top">271-22-0060 Darmstadt, Neubau Rheinstraßenbrücke</p>
            <p class="avs-dashboard-error-message-bottom">Verbindung unterbrochen</p>
            <hr>
          </div>
          <div class="px-3">
            <p class="pb-0 mb-0 avs-dashboard-error-message-top">271-22-0060 Darmstadt, Neubau Rheinstraßenbrücke</p>
            <!--TODO Link muss implementiert werden-->
            <p class="avs-dashboard-error-message-bottom">Verbindung unterbrochen</p>
            <hr>
          </div>
          <div class="px-3 pt-2 text-start">
            <button type="button" class="btn btn-primary ms-0 avs-dashboard-button rounded-0 border-0 shadow">Zur
              Übersicht</button><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>