<ng-container>
    <div *ngIf="routeStation">
        <div class="d-flex flex-column m-2">
            <span class="text-bg-danger ps-2">Name: {{ routeStation.routeStation.name}} </span>
            <span class="text-center "> SAM ID: {{ routeStation.routeStation.samId}} </span>
        </div>

        <div *ngFor="let kanal of routeStation?.routeStation?.dataEndChannels">
            <div *ngIf="kanal.fg === 4 && kanal.localbusAddress === 6 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)">
                <!-- Blinker -->
                <div class="d-flex bg-light m-2 border border-2 border-secondary" style="cursor: pointer;">
                    <ng-container *ngIf="routeStation?.deviceParameter?.online">
                        <div *ngIf="routeStation?.deviceParameter?.blinker?.indicator === 'ausschalten'"
                            class="d-flex justify-content-between bg-light m-2" style="margin-top: 32px !important;">
                            <div class="d-flex" style="width: 65px;">
                                <div class="p-3 rounded-5 border border-black"
                                    style="background-color: white; height: 25px; width: 25px; margin-right: 4px;">
                                </div>
                                <div class="p-3 rounded-5 border border-black"
                                    style="background-color: white; height: 25px; width: 25px">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="routeStation?.deviceParameter?.blinker?.indicator !== 'ausschalten' && routeStation?.deviceParameter?.blinker?.indicator !== null && routeStation?.deviceParameter?.blinker?.indicator !== 'null'"
                            class="d-flex justify-content-between bg-light m-2 " style="margin-top: 32px !important;">
                            <div class="d-flex" style="width: 65px;">
                                <div class="p-3 rounded-5 border border-black"
                                    style="background-color: yellow; height: 25px; width: 25px; margin-right: 4px;">
                                </div>
                                <div class="p-3 rounded-5 border border-black"
                                    style="background-color: yellow; height: 25px; width: 25px">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!routeStation?.deviceParameter?.online">
                        <div class="d-flex justify-content-between bg-light m-2" style="margin-top: 32px !important;">
                            <div class="d-flex" style="width: 65px;">
                                <div class="p-3 rounded-5 border border-black"
                                    style="background-color: gray; height: 25px; width: 25px; margin-right: 4px;">
                                </div>
                                <div class="p-3 rounded-5 border border-black"
                                    style="background-color: gray; height: 25px; width: 25px">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <avs-manual-control-result class="w-100" [scenarioManual]="getProperScenarioManual(kanal.id)"
                        [users]="users"></avs-manual-control-result>
                </div>
            </div>

            <!-- Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro -->
            <div *ngIf="kanal.fg === 1 && kanal.localbusAddress === 11 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)"> <!-- Sensoren -->
                <div class="d-flex bg-light m-2 border border-2 border-secondary" style="cursor: pointer;">
                    <div class="d-flex flex-column p-1" style="width: 95px; white-space: nowrap;">
                        <span class="">Smart Micro</span>
                        <span>{{ kanal.de === 1 ? 'Hauptfahrspur' : (kanal.de-1 + ' .Überholspur')}}</span>
                    </div>
                    <div>
                        <img height="70" src="assets/icons/data-end-channel/radar_aq.svg">
                    </div>
                    <avs-manual-control-result [scenarioManual]="getProperScenarioManual(kanal.id)"
                        [users]="users"></avs-manual-control-result>
                </div>
            </div>
            <!-- Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro -->

            <div *ngIf="kanal.fg === 1 && kanal.localbusAddress === 3 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)">

                <div class="d-flex bg-light m-2 border border-2 border-secondary" style="cursor: pointer;">
                    <div class="d-flex flex-column p-1" style="width: 95px; white-space: nowrap;">
                        <div *ngIf="kanal.de === 1 || kanal.de === 2" class="d-flex flex-column">
                            <span>VIAFALCON</span>
                            <span>{{ kanal.de === 1 ? 'Hauptfahrspur' : (kanal.de-1 + ' .Überholspur')}}</span>
                        </div>
                        <div *ngIf="kanal.de > 159" class="d-flex flex-column">
                            <span>ASIM</span>
                            <span>ADEC</span>
                            <span>{{ kanal.de === 160 ? 'Hauptfahrspur' : (kanal.de-160 + ' .Überholspur')}}</span>
                        </div>
                    </div>
                    <img height="70" src="assets/icons/data-end-channel/radar_aq.svg">

                    <avs-manual-control-result [scenarioManual]="getProperScenarioManual(kanal.id)" [users]="users">
                    </avs-manual-control-result>
                </div>
            </div>

            <div *ngIf="kanal.fg === 4 &&( kanal.localbusAddress === 2 || kanal.localbusAddress === 1 )&& kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)">
                <div class="d-flex justify-content-between  bg-light m-2 border border-2 border-secondary"
                    style="cursor: pointer;">
                    <div *ngIf="!routeStation?.deviceParameter?.online" class="p-2">
                        <img height="70" src="assets/led_default.svg">
                        <!-- Auf welcher ebene baue ich das Bild ein? ins result? gehört ja dazu, macht das sinn? -->
                    </div>
                    <div *ngIf="routeStation?.deviceParameter?.online " class="p-2  position-relative">
                        <img *ngIf="routeStation.deviceParameter.led.stellcode && routeStation.deviceParameter.led.indicator === 'einschalten'"
                            height="70"
                            [src]="environment.apiAddress + getPathByStellcodeId(routeStation.deviceParameter.led.stellcode)">
                        <img *ngIf="routeStation?.deviceParameter?.led?.isAnimated" src="assets/icons/shuffle.svg"
                            alt="animatedImage" class="position-absolute bg-white rounded-5 p-1 "
                            style="top: 9px; right: 9px; height: 14px">
                        <img *ngIf="!routeStation.deviceParameter.led.stellcode" height="70"
                            src="assets/led_default.svg">
                        <img *ngIf="routeStation?.deviceParameter?.led?.indicator ==='ausschalten'" height="70"
                            src="assets/led_default.svg">
                    </div>
                    <avs-manual-control-result style="margin-right: 13px;"
                        [scenarioManual]="getProperScenarioManual(kanal.id)" [users]="users"
                        [images]="images"></avs-manual-control-result>
                </div>
            </div>

            <div *ngIf="kanal.fg === 4 && kanal.localbusAddress === 8 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)" style="cursor: pointer;">
                <div class="d-flex justify-content-between  bg-light m-2 border border-2 border-secondary"
                    style="cursor: pointer;">
                    <div *ngIf="!routeStation?.deviceParameter?.online" class="p-2">
                        <img height="70" src="assets/led_default.svg">
                        <!-- Auf welcher ebene baue ich das Bild ein? ins result? gehört ja dazu, macht das sinn? -->
                    </div>
                    <div *ngIf="routeStation?.deviceParameter?.online " class="p-2  position-relative">
                        <img *ngIf="routeStation.deviceParameter.led.stellcode && routeStation.deviceParameter.led.indicator === 'einschalten'"
                            height="70"
                            [src]="environment.apiAddress + getPathByStellcodeId(routeStation.deviceParameter.led.stellcode)">
                        <img *ngIf="routeStation?.deviceParameter?.led?.isAnimated" src="assets/icons/shuffle.svg"
                            alt="animatedImage" class="position-absolute bg-white rounded-5 p-1 "
                            style="top: 9px; right: 9px; height: 14px">
                        <img *ngIf="!routeStation.deviceParameter.led.stellcode" height="70"
                            src="assets/led_default.svg">
                        <img *ngIf="routeStation?.deviceParameter?.led?.indicator ==='ausschalten'" height="70"
                            src="assets/led_default.svg">
                    </div>
                    <avs-manual-control-result style="margin-right: 13px;"
                        [scenarioManual]="getProperScenarioManual(kanal.id)" [users]="users"
                        [images]="images"></avs-manual-control-result>
                </div>
            </div>
        </div>
    </div>
</ng-container>