import { Component, Input, OnInit } from '@angular/core';
import { ChangelogMqReport } from '../../interfaces/changelog-mq-report.interface';
import { ChangelogMqReportCollection } from '../../interfaces/changelog-mq-report-collection.interface';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'avs-changelog-mq-graph',
  templateUrl: './changelog-mq-graph.component.html',
  styleUrls: ['./changelog-mq-graph.component.css']
})
export class ChangelogMqGraphComponent implements OnInit {

  @Input() mqReports: ChangelogMqReport[] = []; /* ggf doch schon die collection?  */
  @Input() samId: number = 0;
  @Input() index: number = 0;

  public labelCollection: string[] = [];
  public resultCollection: number[] = [];

  public lkwAverageSpeedCollection: number[] = [];
  public pkwAverageSpeedCollection: number[] = [];
  public kfzTrafficVolumeCollection: number[] = [];
  public lkwTrafficVolumeCollection: number[] = [];
  public pkwTrafficVolumeCollection: number[] = [];


  public chart: any;
  public chartData: any;
  public canvasId: string = "MyChart" + this.samId + this.index;
  constructor() { }

  public ngOnInit(): void {
    this.canvasId = "MyChart" + this.samId + this.index;
    this.chartData = this.mqReports
    setTimeout(() => {

      this.chartData.forEach((element: { measuredAt: string, lkwAverageSpeed: number, pkwAverageSpeed: number, kfzTrafficVolume: number, lkwTrafficVolume: number, pkwTrafficVolume: number }) => {

        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection.push(dateTime)
      });
      this.chartData.forEach((element: { lkwAverageSpeed: number, pkwAverageSpeed: number, kfzTrafficVolume: number, lkwTrafficVolume: number, pkwTrafficVolume: number }) => {

        this.lkwAverageSpeedCollection.push(element.lkwAverageSpeed)
        this.pkwAverageSpeedCollection.push(element.pkwAverageSpeed)
        this.kfzTrafficVolumeCollection.push(element.kfzTrafficVolume)
        this.lkwTrafficVolumeCollection.push(element.lkwTrafficVolume)
        this.pkwTrafficVolumeCollection.push(element.pkwTrafficVolume)

      });
      this.createChart();
    }, 1000);
  }

  public exportChart(): void {
    let canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
     let dataURL = canvas.toDataURL('image/png');
     let link = document.createElement('a');
     link.href = dataURL;
     link.download = 'chart.png';
     link.click();
   }
  private createChart(): void {
    this.chart = new Chart(this.canvasId, {
      type: 'line',

      data: {
        labels: this.labelCollection,
        datasets: [
          {
            label: "Durchschnittsgeschiwndigkeit LKW in km/h",
            data: this.lkwAverageSpeedCollection,
            backgroundColor: 'blue',
            fill: false,
            borderColor: 'rgba(0, 0, 255, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Durchschnittsgeschwindigkeit PKW in km/h",
            data: this.pkwAverageSpeedCollection,
            backgroundColor: 'red',
            fill: false,
            borderColor: 'rgba(255, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Anzahl KFZ",
            data: this.kfzTrafficVolumeCollection,
            backgroundColor: 'green',
            fill: false,
            borderColor: 'rgba(0, 255, 255, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Anzahl LKW",
            data: this.lkwTrafficVolumeCollection,
            backgroundColor: 'yellow',
            fill: false,
            borderColor: 'rgba(0, 255, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Anzahl PKW",
            data: this.pkwTrafficVolumeCollection,
            backgroundColor: 'black',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },


        ]
      },
      options: {
        aspectRatio: 2.5,

      },
      plugins: [
        {
          id: 'customCanvasBackgroundColor',
          beforeDraw: function (chart: any) {
            const ctx = chart.ctx;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        }
      ]

    });
  }
}
