import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {RouteStationService} from "../../shared/services/route-station.service";
import {ProjectRouteStation} from "../../shared/interfaces/project-route-station.interface";

@Component({
  selector: 'avs-route-station-add-modal',
  templateUrl: './route-station-add-modal.component.html',
  styleUrls: ['./route-station-add-modal.component.css']
})
export class RouteStationAddModalComponent implements OnInit {
  public routeStations: ProjectRouteStation[] = [];
  public filter: string = "";

  constructor(private dialogRef: MatDialogRef<RouteStationAddModalComponent>,
              private stationService: RouteStationService) {}

  public ngOnInit() {
    this.stationService.getUnassignedRouteStations().subscribe(allStations => {
      this.routeStations = allStations;
    }, error => {this.dialogRef.close();
    });
  }

  public onCloseBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSearchValueChange(value: string): void {
    this.filter = value;
  }

  public getFilteredInput(): ProjectRouteStation[]{
    return this.routeStations.filter(
        s => s.name.toLowerCase().includes(this.filter.toLowerCase()) ||
            s.samId.toString().includes(this.filter.toString()));
  }

  public onItemClicked(item: any): void {
      this.dialogRef.close(item);
  }
}
