<div class="avs-schema-station-container">
    <div class="m-2 border-bottom d-flex justify-content-between">
        <h5 class="fw-semibold">
            <span class="text-secondary">Station: </span>
            <button *canAccess="[1]" type="button" class="btn btn-outline-danger"
                (click)="onCloseAndNavigateToStation()">{{data.routeStation.name}}</button>
        </h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseBtnClicked()">
    </div>
    <div class="p-2 mt-3 d-flex justify-content-around">
        <avs-project-route-station [routeStation]="data" [images]="projectImages" [isModalOpen]="true">
        </avs-project-route-station>
        <!-- ProjektImages kommen von hier, also muss hier die rotation implementiert werden -->
        <div>
            <div class="mb-5 d-flex justify-content-end flex-column " *canAccess="[1]">
                <button
                    class="btn btn-sm btn-outline-secondary rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                    (click)="onDeleteStationFromSchemaClicked()">
                    <span class="mb-auto">Station entfernen</span>
                    <img height="24" src="assets/icons/trash.svg" alt="delete station from schema" class="p-1 ms-2">
                </button>
                <button *ngIf="data.deviceParameter.solar.batteriespannung"
                    class="btn mt-1 btn-sm btn-outline-secondary rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                    (click)="onShowVoltageDiagramFromSchemaClicked()">
                    <span class="mb-auto">Akkuspannung</span>
                    <img height="24" src="assets/icons/bar-chart-fill.svg" alt="delete station from schema"
                        class="p-1 ms-2">
                </button>
                <button *ngIf="data.deviceParameter.solar.batteriespannung"
                    class="btn mt-1 btn-sm btn-outline-secondary rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                    (click)="onShowCurrentDiagramFromSchemaClicked()">
                    <span class="mb-auto">Stromverbrauch</span>
                    <img height="24" src="assets/icons/bar-chart-fill.svg" alt="delete station from schema"
                        class="p-1 ms-2">
                </button>
                <button *ngIf="data.deviceParameter.solar.batteriespannung"
                    class="btn mt-1 btn-sm btn-outline-secondary rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                    (click)="onShowSpeedChartFromSchemaClicked()">
                    <span class="mb-auto">Sensordaten</span>
                    <img height="24" src="assets/icons/bar-chart-fill.svg" alt="delete station from schema"
                        class="p-1 ms-2">
                </button>

                <div *canAccess="[1]">
                    <button *ngIf="data.deviceParameter.solar.batteriespannung"
                        class="btn mt-1 btn-sm btn-outline-secondary rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                        (click)="onShowSTMDiagramFromSchemaClicked()">
                        <span class="mb-auto">STM Data</span>
                        <img height="24" src="assets/icons/bar-chart-fill.svg" alt="delete station from schema"
                            class="p-1 ms-2">
                    </button>
                </div>
                <button *ngIf="data.deviceParameter.solar.batteriespannung"
                    class="btn mt-1 btn-sm btn-outline-secondary rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                    (click)="onShowSolarDetailsFromSchemaClicked()">
                    <span class="mb-auto">Solardetails</span>
                    <img height="24" src="assets/icons/sun-fill.svg" alt="delete station from schema" class="p-1 ms-2">
                </button>
            </div>
            <div *ngIf="data.deviceParameter.online && data.deviceParameter.typ !== 2">
                <div class="mb-2 pt-5 d-flex justify-content-end">
                    <div>
                        <div [ngClass]="isKanalSelectOpen ? 'border-danger' : ''"
                            class="border d-flex justify-content-between avs-schema-modal-btn shadow" role="button"
                            (click)="onKanalSelectClicked()">
                            <div class="ms-1 p-1">{{selectedKanal ? selectedKanal.name : 'Kanal'}}</div>
                            <img *ngIf="!isKanalSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
                                alt="caret-down open select">
                            <img *ngIf="isKanalSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
                                alt="caret-up close select">
                        </div>
                        <avs-dropdown *ngIf="isKanalSelectOpen" class="position-absolute avs-schema-modal-btn"
                            [isHeaderActive]="false" [activeItem]="selectedKanal"
                            (onItemClicked)="onKanalSelectValueChange($event)" [selectInput]="kanalSelectInput"
                            [isSmallDropdown]="true">
                        </avs-dropdown>
                    </div>
                </div>
                <div class="mb-2 d-flex justify-content-end">
                    <button
                        class="btn btn-sm btn-outline-danger rounded-0 shadow avs-schema-modal-btn d-flex justify-content-between"
                        (click)="onSetImageClicked(data.deviceParameter.led.stellcode, 0)">
                        <span class="mb-auto">Bild aus</span>
                        <img height="24" src="assets/icons/hide_image.svg" alt="delete station from schema"
                            class="p-1 ms-2">
                    </button>
                </div>
                <div class="mb-2 d-flex justify-content-end" *ngIf="isBlinker">
                    <mat-slide-toggle class="mt-2" color="warn" (change)="onBlinkerToggle($event)"
                        [checked]="data.deviceParameter.blinker.stellcode === 61 && data.deviceParameter.blinker.indicator === 'einschalten'">
                        <span>Blinker</span>
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.deviceParameter.online && data.deviceParameter.typ !== 2">
        <div class="border-top m-2">
            <p class="text-center" [ngClass]="selectedKanal ? 'text-secondary' : 'text-danger'">
                {{selectedKanal ? selectedKanal.name : 'Kein Kanal ausgewählt'}}
            </p>
        </div>
        <div class="row avs-project-images-container mt-3">
            <ng-container *ngFor="let image of images">
                <div (click)="onSetImageClicked(image.stellcode, 1)" class="mb-3 avs-project-images">
                    <div class="d-flex justify-content-center position-relative mt-2 ">
                        <img *ngIf="image.isAnimated" src="assets/icons/shuffle.svg" alt="animatedImage"
                            class="position-absolute bg-white rounded-5 p-1 "
                            style="top: 2px; right: 12px; height: 14px">
                        <img [src]="environment.apiAddress + image.path">
                    </div>
                    <div class="small text-center">Stellcode: {{image.stellcode}}</div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<!-- <div class="d-flex justify-content-center position-relative">
    <img *ngIf="image.isAnimated" src="assets/icons/shuffle.svg" alt="animatedImage"
        class="position-absolute bg-white rounded-5 p-1 "
        style="top: 1px; right: 12px; height: 20px">
    <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
</div> -->