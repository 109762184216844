import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LisaMessagesService } from '../shared/services/lisa-messages.service';
import { LisaMessage } from './interfaces/lisa-message.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';


@Component({
  selector: 'avs-project-lisamessage',
  templateUrl: './project-lisamessage.component.html',
  styleUrls: ['./project-lisamessage.component.css']
})
export class ProjectLisamessageComponent implements OnInit {
  /* tabelle */
  displayedColumns: string[] = ['imei', 'timeStamp', 'voltage', 'message', 'lat', 'roll', 'pitch', 'cycles'];
  dataSource?: MatTableDataSource<LisaMessage>;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  public messageCollection: LisaMessage[] = [];

  /* tab */
  public tabs = ['Tabelle', 'Karte'];
  public selected = new FormControl(0);
  public activeTab: string = 'Tabelle';


  constructor(private lisaMessagesService: LisaMessagesService) { }

  public ngOnInit(): void {
    this.lisaMessagesService.getAllLisaMessages().subscribe((data) => {
      this.messageCollection = data;
      this.dataSource = new MatTableDataSource(this.messageCollection);

      /* sollte eigentlich in die ngafter view init, wird aber getriggert bevor die datasource defioniert wurde, somit funktioniert das nicht korrekt */
      if (this.paginator)
        this.dataSource!.paginator = this.paginator;
      if (this.sort)
        this.dataSource!.sort = this.sort;
    }
    )
    this.createMapPins();
  }

  public async createMapPins() {

  }




  public tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.activeTab = tabChangeEvent.tab.textLabel;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource!.filter = filterValue.trim().toLowerCase();

    if (this.dataSource?.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public ngAfterViewInit(): void {

  }

  lastSunday(year: number, month: number) {
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);
    return date;
  }

  isCEST(d: Date): boolean {

    let yearString = d as unknown as number;
    let startCET: Date = this.lastSunday(yearString, 3);
    let endCET: Date = this.lastSunday(yearString, 10);

    return !(startCET < d && d < endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }
}
