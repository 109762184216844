import { Component, Input } from '@angular/core';
import { ProjectGroup } from "../../shared/interfaces/project-group.interface";
import { GroupMetaData, GroupMetaDataTypes } from "../shared/interfaces/group-meta.interface";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { getDiagramTypeSelect, getDiagramTypeString } from "../../shared/enums/diagram-type.enum";
import { getTrafficRulesSelect, getTrafficRulesString } from "../../shared/enums/traffic-rules.enum";
import { getGroupTypeSelect, getGroupTypeString } from "../../shared/enums/group-type.enum";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { ModalService } from "../../../shared/modal/shared/modal.service";
import { ProjectPositionData } from "../shared/interfaces/project-position.interface";
import { GroupService } from "../../shared/services/group.service";
import { MatDialog } from "@angular/material/dialog";
import { RouteStationAddModalComponent } from "../route-station-add-modal/route-station-add-modal.component";
import { ProjectDataService } from "../../shared/services/project-data.service";
import { PositionGmapService } from '../shared/services/position-gmap.service';
import { ImageService } from '../../shared/services/image.service';

@Component({
  selector: 'avs-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.css']
})
export class GroupSettingsComponent {
  @Input() activeGroup: ProjectGroup | undefined;
  @Input() routeStations: ProjectRouteStation[] = [];

  public groupMetaData: GroupMetaData | undefined;
  public isFunctionDropdownOpen: boolean = false;
  public selectedFunction: SelectInput = { name: "An", id: 0 };
  public functionSelect: SelectInput[] = [{ name: 'An', id: 0 }, { name: 'Aus', id: 1 }];
  public isDeKanalGroupStellcodeDropdownOpen: boolean = false;
  public selectedDeKanalGroupStellcode: SelectInput = { name: "Kanal" };
  public deKanalGroupStellcodeSelect: SelectInput[] = [{ name: 'Kanal 1', id: 1 }, { name: 'Kanal 2', id: 2 }, { name: 'Kanal 3', id: 3 }, { name: 'Kanal 4', id: 4 }, { name: 'Kanal 5', id: 5 }];

  public isGroupTypesSelectOpen: boolean = false;
  public isDiagramTypesSelectOpen: boolean = false;
  public isTrafficRulesSelectOpen: boolean = false;
  public groupTypeSelect: SelectInput[] = getGroupTypeSelect();
  public diagramTypeSelect: SelectInput[] = getDiagramTypeSelect();
  public trafficRulesSelect: SelectInput[] = getTrafficRulesSelect();

  public stellcode: number = 0;
  public groupPositionData: ProjectPositionData | undefined;
  protected readonly GroupMetaDataTypes = GroupMetaDataTypes;
  protected readonly getGroupTypeString = getGroupTypeString;
  protected readonly getDiagramTypeString = getDiagramTypeString;
  protected readonly getTrafficRulesString = getTrafficRulesString;

  constructor(private alertService: AlertService,
    private modalService: ModalService,
    private projectDataService: ProjectDataService,
    private groupService: GroupService,
    private positionGmapService: PositionGmapService,
    private dialog: MatDialog,
    private imageService: ImageService) { }

  public onMetaValueChange(newValue: any, type: GroupMetaDataTypes): void {
    if (!this.groupMetaData) {
      this.groupMetaData = {
        name: undefined, routeName: undefined, groupType: undefined, diagramType: undefined,
        isActive: undefined, trafficRules: undefined
      };
    }
    if (!this.activeGroup) { return; }
    switch (type) {
      case GroupMetaDataTypes.name:
        this.groupMetaData.name = newValue;
        break;
      case GroupMetaDataTypes.routeName:
        this.groupMetaData.routeName = newValue;
        break;
      case GroupMetaDataTypes.groupType:
        this.groupMetaData.groupType = newValue;
        this.activeGroup.groupType = newValue.id;
        this.isGroupTypesSelectOpen = false;
        break;
      case GroupMetaDataTypes.diagramType:
        this.groupMetaData.diagramType = newValue.name;
        this.activeGroup.diagramType = newValue.id;
        this.isDiagramTypesSelectOpen = false;
        break;
      case GroupMetaDataTypes.trafficRules:
        this.groupMetaData.trafficRules = newValue.name;
        this.activeGroup.trafficRules = newValue.id;
        this.isTrafficRulesSelectOpen = false;
        break;
    }
  }

  public onGetActiveSelect(type: GroupMetaDataTypes, value: number): SelectInput {
    switch (type) {
      case GroupMetaDataTypes.groupType:
        return {
          name: getGroupTypeString(value),
          id: value,
        } as SelectInput;
      case GroupMetaDataTypes.diagramType:
        return {
          name: getDiagramTypeString(value),
          id: value,
        } as SelectInput;
      case GroupMetaDataTypes.trafficRules:
        return {
          name: getTrafficRulesString(value),
          id: value,
        } as SelectInput;
      default:
        return {} as SelectInput;
    }
  }

  public onGroupPositionChange(newValue: string, isLatitude: boolean): void {
    if (!this.groupPositionData) {
      this.groupPositionData = { latitude: undefined, longitude: undefined };
    }
    if (isLatitude) {
      this.groupPositionData.latitude = +newValue;
    } else {
      this.groupPositionData.longitude = +newValue;
    }
  }

  public onActiveGroupChange(): void {
    if (this.activeGroup) {
      this.activeGroup.isActive = !this.activeGroup.isActive;
      if (!this.groupMetaData) {
        this.groupMetaData = {
          name: undefined, routeName: "", groupType: undefined, diagramType: undefined,
          isActive: this.activeGroup.isActive, trafficRules: undefined
        };
      }
    }
  }

  public onArchiveGroupChange(): void {
    if (this.activeGroup) {
      this.activeGroup.isArchived = !this.activeGroup.isArchived;
      if (!this.groupMetaData) {
        this.groupMetaData = {
          name: undefined, routeName: undefined, groupType: undefined, diagramType: undefined,
          isActive: this.activeGroup.isActive, trafficRules: undefined, isArchived: this.activeGroup.isArchived
        };
      }
    }
  }

  public onAddStationBtnClicked(): void {
    const dialogRef = this.dialog.open(RouteStationAddModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.activeGroup) {
          this.groupService.editProjectRouteStations(this.activeGroup.id, [result.id], []).subscribe(stations => {
            this.alertService.alert('neue Station "' + result.name + '" zur Gruppe hinzugefügt', AlertTypes.success);
            this.projectDataService.updateProjectRouteStations(stations);
          });
        }
      }
    });
  }

  public onStationClicked(routeStation: ProjectRouteStation): void {
    this.projectDataService.updateActiveRouteStation(routeStation);
  }

  public onDeleteRouteStationClicked(routeStation: ProjectRouteStation) {
    this.modalService.openDialog('Station aus der Gruppe entfernen?', 'Nein', 'Ja').subscribe(
      btnYes => {
        if (btnYes) {
          this.deleteRouteStation(routeStation.id);
        }
      }
    )
  }

  public onSaveMetaDataClicked(): void {
    if (this.activeGroup) {
      this.activeGroup.name = this.groupMetaData?.name === undefined ? this.activeGroup.name : this.groupMetaData.name;
      this.activeGroup.routeName = this.groupMetaData?.routeName === undefined ? this.activeGroup.routeName : this.groupMetaData.routeName;
      this.groupService.editProjectGroup(this.activeGroup.id, this.activeGroup).subscribe(() => {
        this.alertService.alert('Guppen Metadaten wurden geändert', AlertTypes.info)
        this.groupMetaData = undefined;
      });
    }
  }

  public onSavePositionDataClicked(): void {
    if (this.activeGroup) {
      this.activeGroup.latitude = this.groupPositionData?.latitude ?
        this.groupPositionData.latitude : this.activeGroup.latitude;
      this.activeGroup.longitude = this.groupPositionData?.longitude ?
        this.groupPositionData.longitude : this.activeGroup.longitude;
      this.groupService.editProjectGroup(this.activeGroup.id, this.activeGroup).subscribe(() => {
        this.alertService.alert('Gruppen Positionsdaten wurden geändert', AlertTypes.info);
        this.groupPositionData = undefined;
      });
    }
  }

  public onCopyCoordinatesLinkBtnClick(): void {
    let latitude = this.activeGroup?.latitude;
    let longitude = this.activeGroup?.longitude;

    this.positionGmapService.copyCoordinatesLink(latitude as number, longitude as number)
  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onDeKanalGroupStellcodeDropdownClicked(): void {
    this.isDeKanalGroupStellcodeDropdownOpen = !this.isDeKanalGroupStellcodeDropdownOpen;
  }

  public setGroupStellcode(): void {
    if (this.activeGroup)
      this.imageService.setImageForGroup(this.activeGroup.id, this.stellcode, this.selectedFunction.id, this.selectedDeKanalGroupStellcode.id).subscribe(() => this.alertService.alert('Stellcode für Gruppe gesetzt', AlertTypes.info))
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
  }
  public onDeKanalGroupStellcodeClicked(item: SelectInput): void {
    this.isDeKanalGroupStellcodeDropdownOpen = false;
    this.selectedDeKanalGroupStellcode.name = item.name
    this.selectedDeKanalGroupStellcode.id = item.id
  }

  private deleteRouteStation(stationId: string): void {
    if (this.activeGroup) {
      this.groupService.editProjectRouteStations(this.activeGroup.id, [], [stationId]).subscribe(stations => {
        this.alertService.alert('Station aus der Gruppe entfernt', AlertTypes.info);
        this.projectDataService.updateProjectRouteStations(stations);
      });
    }
  }
}
