import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'avs-lisa-gmap',
  templateUrl: './lisa-gmap.component.html',
  styleUrls: ['./lisa-gmap.component.css']
})
export class LisaGmapComponent implements OnInit {
  @Input() pins: any[] = [];
  public map?: google.maps.Map;

  /* center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 4; */



  constructor() { }

  public ngOnInit(): void {
   
  }

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 20.5937,
    lng: 78.9629,
  };
  zoom = 2;
  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
}