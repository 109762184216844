<div *ngIf="activeGroup; else noActiveProject" class="m-0">
    <div class="card rounded-0 shadow mt-4 p-2">
        <div class="fw-semibold d-flex justify-content-between">
            <div class="fw-semibold">Sonderprogramme: <span class="text-danger">{{activeGroup.name}} </span>
                <span class="text-danger" *ngIf="!activeGroup.isActive">- Gruppe ist deaktiviert </span>
            </div>
            <img (click)="onCreateScenarioBtnClicked()" class="avs-icon-add mb-3" src="assets/icons/plus.svg"
                alt="Sonderprogramm erstellen">
        </div>
        <div> <span> <b>Hinweis:</b> Ein Sonderprogramm ist nur wirksam, wenn eine SWA existiert </span>
        </div>
    </div>
    <div class="row">
        <ng-container *ngFor="let scenario of scenarios">
            <div class="col-12 mt-1">
                <div class="card rounded-0 shadow mt-2 p-2 ">
                    <div class="row">
                        <div class="d-flex justify-content-between">
                            <div class="ms-2"> <span class="fw-semibold text-secondary col"> {{ scenario.name}} </span>
                            </div>
                            <div class="me-2">
                                <img *ngIf="!scenario.enabled" class="avs-icon-play p-2" src="assets/icons/play-btn.svg"
                                    alt="icon-play-scenario" style="height: 36px"
                                    (click)="onPlayScenarioBtnClicked(scenario)" matTooltip="Sonderprogramm starten">
                                <img *ngIf="scenario.enabled" class="avs-icon-delete p-2"
                                    src="assets/icons/pause-btn.svg" alt="icon-stop-scenario" style="height: 36px"
                                    (click)="onStopScenarioBtnClicked(scenario)" matTooltip="Sonderprogramm anhalten">
                                <img class="avs-icon-delete p-2" src="assets/icons/pencil.svg" alt="icon-edit-scenario"
                                    style="height: 36px" (click)="onEditScenarioBtnClicked(scenario)">
                                <img class="avs-icon-delete p-2" src="assets/icons/trash.svg" alt="icon-delete-scenario"
                                    style="height: 36px" (click)="onDeleteScenarioBtnClicked(scenario)">
                            </div>
                        </div>
                    </div>
                    <div class="overflow-x-scroll pb-3 ">
                        <div class="d-flex ">
                            <ng-container *ngFor="let areaA of line.areaA">
                                <div *ngIf="!areaA.isHidden" class="border-2 border-black border">
                                    <div class="text-secondary text-center"
                                        [ngStyle]="{'min-width.px': width[areaA.x -1]}"> Linie {{areaA.x}}</div>
                                    <div *ngIf="areaA.isActive" class="w-100">
                                        <div *ngIf="areaA.station" class="d-flex justify-content-center  mb-2 "
                                            style="gap: 4px">

                                            <ng-container *ngFor="let station of areaA.station">
                                                <div class="border border-1 border-black h-100" style="width: 280px; ">
                                                    <avs-modular-route-station [routeStation]="station"
                                                        (myEvent)="handleEvent($event)" [projectId]="activeProject!.id"
                                                        [scenarioId]="scenario.id"
                                                        [scenarioManuals]="getScenarioManualByScenarioID(scenario)"
                                                        [images]="allImages" [users]="activeProjectsUsers"
                                                        style="width: 280px;"></avs-modular-route-station>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="border-top border-white  w-100"></div>
                        <div class="d-flex ">
                            <ng-container *ngFor="let areaB of line.areaB">
                                <div *ngIf="!areaB.isHidden" class="border-2 border-black border">
                                    <div class="text-secondary text-center"
                                        [ngStyle]="{'min-width.px': width[areaB.x -1]}"> Linie {{areaB.x}}</div>
                                    <div *ngIf="areaB.isActive" class="w-100">
                                        <div *ngIf="areaB.station" class="d-flex justify-content-center   mb-2"
                                            style="gap: 4px">
                                            <ng-container *ngFor="let station of areaB.station">
                                                <div class="border border-1 border-black h-100 " style="width: 280px;">
                                                    <avs-modular-route-station [routeStation]="station"
                                                        (myEvent)="handleEvent($event)" [projectId]="activeProject!.id"
                                                        [scenarioId]="scenario.id"
                                                        [scenarioManuals]="getScenarioManualByScenarioID(scenario)"
                                                        [images]="allImages" [users]="activeProjectsUsers"
                                                        style="width: 280px;"></avs-modular-route-station>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #noActiveProject>
    <p class="mt-3 fw-semibold text-secondary">Kein Projekt ausgewählt!</p>
</ng-template>