<div class="row">
    <div class="col-2 text-secondary pb-2 border-bottom border-danger">Aktionen</div>
    <div class="col-3 text-secondary pb-2 border-bottom border-danger">Typ</div>
    <div class="col-2 text-secondary pb-2 border-bottom border-danger">Status</div>
    <div class="col-5 row">
        <div class="col-4 text-secondary pb-2 border-bottom border-danger">DE-Kanal</div>
        <div class="col-4 text-secondary pb-2 border-bottom border-danger">Localbus Adresse</div>
        <div class="col-4 text-secondary pb-2 border-bottom border-danger">Eakanalnr</div>
    </div>
</div>
<div class="row" *ngFor="let dataEndChannel of dataEndChannels">
    <div class="col-2 border-bottom">
        <div class="d-flex">
            <img class="avs-icon-edit mt-2 mb-1 p-1" src="assets/icons/pencil.svg"
                 alt="icon-edit dataEndChannel" (click)="onEditBtnClicked(dataEndChannel)">
            <img class="avs-icon-delete mt-2 mb-1 p-1" src="assets/icons/trash.svg"
                 alt="icon-delete dataEndChannel" (click)="onDeleteBtnClicked(dataEndChannel)">
                 <img *ngIf="dataEndChannel.timerId" class="avs-icon-delete mt-2 mb-1 p-1" src="assets/icons/stopwatch.svg"
                 alt="remove timer assignment" (click)="onRemoveTimerAssignment(dataEndChannel.id)" matTooltip="Kanal ID: {{dataEndChannel.id}}">
                 <img *ngIf="dataEndChannel.fG01_Settings.length > 2 || dataEndChannel.fG04_Settings.length > 2 || dataEndChannel.fG06_Settings.length > 2" class="avs-icon-delete mt-2 mb-1 p-1" src="assets/icons/gear.svg"
                 alt="icon-delete dataEndChannel" (click)="showFGSettings(dataEndChannel)">
        </div>
    </div>
    <div class="col-3 border-bottom">
        <div class="d-flex">
            <img class="me-2 mt-2" [src]="getTypeIcon(dataEndChannel.dataEndChannelType)" alt="DataEndChannel Type Icon">
            <div class="mt-1 mb-1">
                <div>{{getDataEndChannelTypeString(dataEndChannel.dataEndChannelType)}}</div>
                <div class="text-secondary small">fg: {{dataEndChannel.fg}}</div>
            </div>
        </div>
    </div>
    <div class="col-2 border-bottom">
        <div class="mt-2 mb-2">
            <span [class]="getChannelStateClassString(dataEndChannel) + ' p-1'">{{getChannelErrorString(dataEndChannel)}}</span>
        </div>
    </div>
    <div class="col-5 row">
        <div class="col-4 border-bottom">
            <div class="mt-2">{{dataEndChannel.de}}</div>
        </div>
        <div class="col-4 border-bottom">
            <div class="mt-2">{{dataEndChannel.localbusAddress}} - {{ getLocalBusTypeString(dataEndChannel.localbusAddress) }}</div>
        </div>
        <div class="col-4 border-bottom">
            <div class="mt-2">{{dataEndChannel.eakanalnr}}</div>
        </div>
    </div>
</div>
