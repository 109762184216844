import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { Scenario } from '../interfaces/scenario.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {

  constructor(private httpService: HttpService) { }

  public createScenario(data: Scenario): Observable<Scenario> {
    return this.httpService.post<Scenario>('Scenario', data);
  }

  public getAllScenarios(): Observable<Scenario[]> {
    return this.httpService.get<Scenario[]>('Scenario');
  }

  public getScenarioById(id: number): Observable<Scenario> {
    return this.httpService.get<Scenario>('Scenario/' + id);
  }

  public updateScenario(scenarioId: number, scenario: Scenario): Observable<Scenario> {
    return this.httpService.put<Scenario>('Scenario/' + scenarioId, scenario);
  }

  public deleteScenario(id: number): Observable<void> {
    return this.httpService.delete<void>('Scenario/' + id);
  }

  public addManualControlToScenario(scenarioId: number, manualControlId: number, deId: number): Observable<void> {
    return this.httpService.get<void>(`Scenario/${scenarioId}/add?manualid=${manualControlId}&DEid=${deId}`);
  }

  public removeManuelControlFromScenario(scenarioId: number, ItemId: number): Observable<void> {
    return this.httpService.delete<void>(`Scenario/${scenarioId}/remove/${ItemId}`);
  }

  public getAllScenariosByGroupId(groupId: number): Observable<Scenario[]> {
    return this.httpService.get<Scenario[]>('Scenario/group/' + groupId);
  }

  public enableScenario(scenarioId: number): Observable<any> {
    return this.httpService.get<any>('Scenario/' + scenarioId + '/enable');
  }

  public disableScenario(scenarioId: number): Observable<any> {
    return this.httpService.get<any>('Scenario/' + scenarioId + '/disable');
  }

}
